h1 {
    font-size: 32pt;
}
h2 {
    font-size: 26pt;
}
h3 {
    font-size: 22pt;
}
h4 {
    font-size: 20pt;
}
img {
    margin: auto;
}

.source-markdown {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}